<template>
  <v-container
    fill-height
    fluid
    class="login-page d-flex justify-content-center item-align-center"
  >
    <v-card width="400px" elevation="7">
      <v-app-bar color="primary" class="text-white pa-1">
        <v-btn
          v-if="!isResetPassword"
          icon
          @click="$router.push({ name: 'login' })"
          class="text-white"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title class="text-center"
          ><h1>Parolamı Unuttum</h1></v-toolbar-title
        >
      </v-app-bar>
      <v-card-text>
        <fragment v-if="isResetPassword">
          <v-form lazy-validation ref="resetPassword" v-model="valid">
            <span>
              <p>Lütfen yeni parolanızı giriniz.</p>
            </span>
            <v-text-field
              v-model="new_password"
              :rules="[
                (v) => !!v || 'Parola alanı boş bırakılamaz',
                (v) =>
                  v?.length >= 8 ||
                  'Parola alanı en az 8 karakterden oluşmalıdır',
              ]"
              label="Parola"
              validate-on-blur
              type="password"
              outlined
              required
            ></v-text-field>
            <v-text-field
              v-model="new_password_confirmation"
              :rules="[
                (v) => !!v || 'Parola Tekrarı alanı boş bırakılamaz',
                (v) =>
                  v?.length >= 8 ||
                  'Parola Tekrarı alanı en az 8 karakterden oluşmalıdır',
              ]"
              label="Parola Tekrar"
              validate-on-blur
              type="password"
              outlined
              required
            ></v-text-field>
            <v-card-actions class="d-flex justify-content-end">
              <v-btn color="error" @click="resetForm"> Sıfırla </v-btn>
              <v-btn
                color="primary"
                @click="onSubmit"
                :loading="loading"
                :disabled="!valid"
              >
                Kaydet
              </v-btn>
            </v-card-actions>
          </v-form>
        </fragment>
        <fragment v-else>
          <v-form lazy-validation ref="forgotPassword" v-model="valid">
            <span>
              <p>
                Parolanızı sıfırlamak için e-posta adresinizi giriniz. E-posta
                adresinize parola sıfırlama linki gönderilecektir.
              </p>
            </span>
            <v-text-field
              v-model="email"
              placeholder="E-posta"
              required
              outlined
              prepend-icon="mdi-account"
              :rules="emailRules"
              type="email"
            ></v-text-field>
            <v-card-actions class="d-flex justify-content-center mt-2">
              <v-btn
                color="primary"
                @click="sendEmail"
                :loading="loading"
                :disabled="!valid"
                >Sıfırlama E-postasını Gönder</v-btn
              >
            </v-card-actions>
          </v-form>
        </fragment>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PasswordService from "@/core/services/api/password.service";

export default {
  name: "SM-Login",
  data() {
    return {
      email: null,
      loader: null,
      loading: false,
      new_password: null,
      new_password_confirmation: null,
      valid: false,
      emailRules: [
        (v) => !!v || "E-Posta alanı boş bırakılamaz",
        (v) =>
          /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) ||
          "Geçerli bir e-posta adresi giriniz",
      ],
    };
  },
  created() {
    if (this.$route.query.token) {
      this.verifyToken();
    }
  },
  methods: {
    resetForm() {
      this.new_password = null;
      this.new_password_confirmation = null;
    },
    async sendEmail() {
      this.loader = "loading";
      const valid = this.$refs.forgotPassword.validate();
      if (!valid) {
        return;
      }
      let message;
      try {
        message = await PasswordService.sendResetPasswordMail(this.email);
        this.$toast.success(message, {
          position: "bottom-right",
          duration: 2000,
        });
      } catch (error) {
        if (error.code === "404") {
          message = "Böyle bir kayitli e-posta bulunamadı.";
        } else {
          message = error.detail;
        }
        this.$toast.error(message, {
          position: "bottom-right",
          duration: 2000,
        });
      }
      this[this.loader] = false;
      this.loader = null;
    },
    async verifyToken() {
      try {
        await PasswordService.verifyResetPasswordToken(
          this.$route.query.email,
          this.$route.query.token
        );
      } catch (error) {
        this.$toast.error(
          "Zamanı dolmuş sıfırlama işlemi, lütfen tekrar sıfırlama e-postası gönderiniz.",
          {
            position: "bottom-right",
            duration: 2000,
          }
        );
        this.$router.push({ name: "forgotten-password" });
      }
    },
    async onSubmit() {
      this.loader = "loading";
      const valid = this.$refs.resetPassword.validate();
      if (!valid) {
        return;
      }
      try {
        await PasswordService.updatePasswordWithToken(
          this.$route.query.email,
          this.$route.query.token,
          this.new_password,
          this.new_password_confirmation
        );
        this.$toast.success("Parolanız başarıyla değiştirildi.", {
          position: "bottom-right",
          duration: 2000,
        });
        this.$router.push({ name: "login" });
      } catch (error) {
        this.$toast.error(error.detail, {
          position: "bottom-right",
          duration: 2000,
        });
      }
      this[this.loader] = false;
      this.loader = null;
    },
  },
  computed: {
    isResetPassword() {
      const query = this.$route.query;
      if (query.email && query.token) {
        return true;
      }
      return false;
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
    },
  },
};
</script>
