var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"login-page d-flex justify-content-center item-align-center",attrs:{"fill-height":"","fluid":""}},[_c('v-card',{attrs:{"width":"400px","elevation":"7"}},[_c('v-app-bar',{staticClass:"text-white pa-1",attrs:{"color":"primary"}},[(!_vm.isResetPassword)?_c('v-btn',{staticClass:"text-white",attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({ name: 'login' })}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1):_vm._e(),_c('v-toolbar-title',{staticClass:"text-center"},[_c('h1',[_vm._v("Parolamı Unuttum")])])],1),_c('v-card-text',[(_vm.isResetPassword)?_c('fragment',[_c('v-form',{ref:"resetPassword",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('span',[_c('p',[_vm._v("Lütfen yeni parolanızı giriniz.")])]),_c('v-text-field',{attrs:{"rules":[
              (v) => !!v || 'Parola alanı boş bırakılamaz',
              (v) =>
                v?.length >= 8 ||
                'Parola alanı en az 8 karakterden oluşmalıdır',
            ],"label":"Parola","validate-on-blur":"","type":"password","outlined":"","required":""},model:{value:(_vm.new_password),callback:function ($$v) {_vm.new_password=$$v},expression:"new_password"}}),_c('v-text-field',{attrs:{"rules":[
              (v) => !!v || 'Parola Tekrarı alanı boş bırakılamaz',
              (v) =>
                v?.length >= 8 ||
                'Parola Tekrarı alanı en az 8 karakterden oluşmalıdır',
            ],"label":"Parola Tekrar","validate-on-blur":"","type":"password","outlined":"","required":""},model:{value:(_vm.new_password_confirmation),callback:function ($$v) {_vm.new_password_confirmation=$$v},expression:"new_password_confirmation"}}),_c('v-card-actions',{staticClass:"d-flex justify-content-end"},[_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.resetForm}},[_vm._v(" Sıfırla ")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":_vm.onSubmit}},[_vm._v(" Kaydet ")])],1)],1)],1):_c('fragment',[_c('v-form',{ref:"forgotPassword",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('span',[_c('p',[_vm._v(" Parolanızı sıfırlamak için e-posta adresinizi giriniz. E-posta adresinize parola sıfırlama linki gönderilecektir. ")])]),_c('v-text-field',{attrs:{"placeholder":"E-posta","required":"","outlined":"","prepend-icon":"mdi-account","rules":_vm.emailRules,"type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-card-actions',{staticClass:"d-flex justify-content-center mt-2"},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":_vm.sendEmail}},[_vm._v("Sıfırlama E-postasını Gönder")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }