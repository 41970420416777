import AxiosInstance from "@/core/services/axios.service";

const PasswordService = {
  sendResetPasswordMail(email) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post("forgot-password", { email: email })
        .then(({ data }) => {
          resolve(data.message);
        })
        .catch(({ response }) => {
          reject(response.data.errors[0]);
        });
    });
  },
  verifyResetPasswordToken(email, token) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post("forgot-password/verify", {
        email: email,
        token: token,
      })
        .then(({ data }) => {
          resolve(data.message);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  updatePasswordWithToken(email, token, password, passwordConfirmation) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post("forgot-password/reset", {
        email: email,
        token: token,
        password: password,
        password_confirmation: passwordConfirmation,
      })
        .then(({ data }) => {
          resolve(data.message);
        })
        .catch(({ response }) => {
          reject(response.data.errors[0]);
        });
    });
  },
  resetPassword(
    old_password,
    new_password,
    new_passwordConfirmation,
    remember_session
  ) {
    return new Promise((resolve, reject) => {
      AxiosInstance.post("user/reset-password", {
        old_password: old_password,
        password: new_password,
        password_confirmation: new_passwordConfirmation,
        remember_session: remember_session,
      })
        .then(({ data }) => {
          resolve(data.message);
        })
        .catch(({ response }) => {
          reject(response.data.errors[0]);
        });
    });
  },
};

export default PasswordService;
